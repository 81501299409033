::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: var(--app-font-base);
    color: var(--gray-color);
}

::-moz-placeholder {
    /* Firefox 19+ */
    font-family: var(--app-font-base);
    color: var(--gray-color);
}

:-ms-input-placeholder {
    /* IE 10+ */
    font-family: var(--app-font-base);
    color: var(--gray-color);
}

:-moz-placeholder {
    /* Firefox 18- */
    font-family: var(--app-font-base);
    color: var(--gray-color);
}