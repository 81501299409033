@import 'src/styles/02-tools/index';

.footer {
    position: fixed;
    bottom: 0;
    height: 3rem;
    background-color: var(--white-color);
    box-shadow: 0 0 2px 1px var(--gray-color);
    width: 100%;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    @include mq-min-width-tablet {
        height: 4rem;
    }

    @include mq-desktop {
        @include justify-content(flex-start);
    }

    h2 {
        font-size: calculateRem(13px);
        @include mq-desktop {
            padding-left: var(--padding-left-base);
        }
    }
}