select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 96 960 960' width='24'><path d='M480 711 240 471l43-43 197 198 197-197 43 43-240 239Z'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 7px;
    padding-right: 2rem;
    background-color: transparent;
    border: solid 1px;
    padding: 0.6rem 0.5rem;
}


.wrapper-select {
    background-color: var(--white-color);
}