input,
textarea,
select {
    font-family: var(--app-font-base);
    padding: 0.5rem;
    min-width: 90vw;
    @include mq-min-width-tablet {
        min-width: 20rem;
    }
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.form-field {
    margin-bottom: 1rem;
}

.form-element-error {
    color: var(--red-color);
    margin-top: 0.5rem;
    margin-bottom: 0;
    font-size: calculateRem(12px);
    @include mq-min-width-tablet {
        max-width: 20rem;
    }
}