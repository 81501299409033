$breakpoint-smalldevice: 320px;
$breakpoint-mobile: 640px;
$breakpoint-tablet: 940px;
$breakpoint-desktop-large: 1600px;

$base: 16px;

:root {
  // white color
  --white-color: #fff;
  // black color
  --black-color: #000;
  // Gray colors
  --gray-color: #b7afaf;
  --light-gray-color: #f6eded;
  // Red color
  --red-color: #ff0000;
  // Theme colors
  --primary-color: #2190c4;
  --secondary-color: #44B5DA;
  // fonts
  --app-font-base: 'Open Sans', sans-serif;
  --padding-left-base: 30vw;
}