@import 'src/styles/02-tools/index';

* {
  box-sizing: border-box;
}

body {
  font-family: var(--app-font-base);
  margin: 0;
  background-color: var(--light-gray-color);
  font-size: $base;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--app-font-base);
  font-weight: bold;
}